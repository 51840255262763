import { Box, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import Card from "@mui/material/Card";

const Styles = makeStyles((theme) => {
  return {
    responsive_card: {
      [theme.breakpoints.down("lg")]: {
        maxWidth: "100% !important",
      },
    },
  };
});

const CardUI = ({
  children,
  maxWidth = 540,
  dashboard = false,
  responsive = "false",
  height,
}) => {
  const classes = Styles();
  return (
    <Paper
      className={responsive === "true" ? classes.responsive_card : ""}
      sx={{
        maxWidth: maxWidth,
        width: "100%",
        borderRadius: dashboard ? "8px" : "16px",
        padding: dashboard ? "32px" : "68px 40px",
        paddingTop: dashboard ? "32px" : "86px",
        WebkitBoxShadow: dashboard
          ? " 0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
          : "0px 10px 20px 0px rgba(0, 0, 0, 0.10)",
        background: "#fff",
        height: height && height,
        overflow: dashboard ? "auto" : "inherit",
        position: "relative",
      }}
    >
      {children}
    </Paper>
  );
};

export default CardUI;
