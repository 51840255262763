import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import LawFirmCard from "../LawFirmCard";
import folderImg from "../../assets/images/folderImg.png";
import { EditIcon } from "../../assets/constants";
import { useSelector } from "react-redux";
import RectangleSkeletons from "../RectangleSkeletons";

const OverviewTab = () => {
  const getAgencyDetails = useSelector((state) => state.getAgencyDetails);
  const { loading, error, agencyDetails } = getAgencyDetails;

  return (
    <>
      {loading ? (
        <Stack gap="25px">
          <Box display="flex" alignItems="center" gap="18px" width="741px">
            <RectangleSkeletons totalRows={1} height={174} />
            <RectangleSkeletons totalRows={1} height={174} />
          </Box>
          <RectangleSkeletons width={741} totalRows={1} height={496} />
        </Stack>
      ) : (
        <Stack gap="25px" width="741px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="18px"
          >
            {/* <LawFirmCard
    headerText=" Active Cases"
    number={134}
    imgName={folderImg}
    alt="Total Cases"
    boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
  /> */}
            <LawFirmCard
              headerText="Total Clients"
              number={agencyDetails?.agency?.clients_count}
              imgName={folderImg}
              alt="Total Cases"
              boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
            />
            <LawFirmCard
              headerText="Total Cases"
              number={agencyDetails?.agency?.cases_count}
              imgName={folderImg}
              alt="Total Cases"
              boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
            />
          </Box>

          <Stack
            p="24px"
            gap="24px"
            bgcolor="#fff"
            boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderRadius="8px"
            >
              <Typography
                variant="subtitle1"
                color="secondary"
                fontWeight={500}
                fontSize="16px"
              >
                Agency Details
              </Typography>
              {/* <IconButton>
      <EditIcon />
    </IconButton> */}
            </Box>
            <Stack gap="4px">
              <Box display="flex" alignItems="center">
                <Stack gap="4px" width="50%" padding="6px">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    fontWeight={400}
                  >
                    Law-firm Name
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    fontSize="16px"
                  >
                    {agencyDetails?.agency?.name
                      ? agencyDetails?.agency?.name
                      : "-"}
                  </Typography>
                </Stack>
                <Stack gap="4px" width="50%" padding="6px">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    fontWeight={400}
                  >
                    Email Address{" "}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    fontSize="16px"
                  >
                    {agencyDetails?.agency?.primary_email
                      ? agencyDetails?.agency?.primary_email
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
              <Box display="flex" alignItems="center">
                <Stack gap="4px" width="50%" padding="6px">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    fontWeight={400}
                  >
                    Contact Number{" "}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    fontSize="16px"
                  >
                    {agencyDetails?.agency?.primary_contact_number
                      ? agencyDetails?.agency?.primary_contact_number
                      : "-"}
                  </Typography>
                </Stack>
                <Stack gap="4px" width="50%" padding="6px">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    fontWeight={400}
                  >
                    Website{" "}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    fontSize="16px"
                  >
                    {agencyDetails?.agency?.website
                      ? agencyDetails?.agency?.website
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
              <Box display="flex" alignItems="center">
                <Stack gap="4px" width="50%" padding="6px">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    fontWeight={400}
                  >
                    Address{" "}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    fontSize="16px"
                  >
                    {agencyDetails?.agency?.address
                      ? agencyDetails?.agency?.address
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
              <Box display="flex" alignItems="center">
                <Stack gap="4px" width="50%" padding="6px">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    fontWeight={400}
                  >
                    Country
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    fontSize="16px"
                  >
                    {agencyDetails?.agency?.country
                      ? agencyDetails?.agency?.country
                      : "-"}
                  </Typography>
                </Stack>
                <Stack gap="4px" width="50%" padding="6px">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    fontWeight={400}
                  >
                    State/Region{" "}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    fontSize="16px"
                  >
                    {agencyDetails?.agency?.region
                      ? agencyDetails?.agency?.region
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default OverviewTab;
