import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import DashboardIcon from "../../icons/DashboardIcon";
import { makeStyles } from "@mui/styles";
import Style from "./style";
import MccAdminLogo from "../../assets/images/mccAdminLogo.png";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import LawFirmIcon from "../../icons/LawFirmIcon";

// const allTabs = [
//   {
//     title: "Account",
//     component: <Profile />,
//     to: "/profile",
//   },
// ];

// const styles = makeStyles((theme) => {
//   return {
//     activeColor: {
//       // selected and (selected + hover) states
//       "&& .Mui-selected, && .Mui-selected:hover": {
//         backgroundColor: "red",
//         "&, & .MuiListItemIcon-root": {
//           color: "pink",
//         },
//       },
//     },
//   };
// });

const dashboardLists = [
  {
    title: "Dashboard",
    icon: <DashboardIcon />,
    to: "/",
  },
  { title: "Law Firms", icon: <LawFirmIcon />, to: "/lawfirms" },
];

const Styles = makeStyles((theme) => Style(theme));

const Sidedrawer = ({ drawerWidth }) => {
  const location = useLocation();
  const classes = Styles();
  const pathName = location?.pathname;
  const [selectedList, setSelectedList] = useState(
    parseInt(localStorage.getItem("adminSelectedIndex")) || 1
  );
  const dispatch = useDispatch();

  // useEffect to handle refreshing the page
  useEffect(() => {
    const storedIndex = parseInt(Cookies.get("adminSelectedIndex"));
    if (storedIndex !== selectedList) {
      setSelectedList(storedIndex || 1);
    }
  }, []);

  useEffect(() => {
    let pageTitle = "MCC Admin";
    if (pathName === "/") {
      pageTitle = "MCC Admin | Dashboard";
    } else if (pathName === "/lawfirms") {
      pageTitle = "MCC Admin | Law Firms";
    }

    document.title = pageTitle;
  }, [pathName]);

  const selectListHandler = (index) => {
    setSelectedList(index);
    Cookies.set("adminSelectedIndex", index);
  };

  return (
    <Box>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            padding: "12px",
            background: "#091433 !important",
          },
        }}
        // className={classes.drawer_bgColor}
        variant="permanent"
        anchor="left"
      >
        <Box padding="12px 8px" width="215px" position="relative">
          <img src={MccAdminLogo} width="100%" alt="mcc admin logo" />
          <Box
            position="absolute"
            top="-4px"
            right="20px"
            borderRadius="4px"
            bgcolor="#fff"
            padding="0 3px"
          >
            <Typography variant="body2" fontWeight="700" color="#091433">
              Admin
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ background: "#292935" }} />

        <List
          sx={{
            height: "100%",
            paddingTop: "12px",
          }}
        >
          {dashboardLists.map((text, index) => (
            <React.Fragment key={index}>
              <ListItem
                disablePadding
                onClick={() => {
                  if (index !== 0) {
                    selectListHandler(index);
                  }
                }}
                sx={{ color: "#fff !important" }}
                className={`${text.className ? classes.settingBottom : ""} ${
                  selectedList === index ? classes.activeTab : ""
                }`}
              >
                <ListItemButton
                  disableRipple
                  disabled={index === 0}
                  component={NavLink}
                  selected={selectedList === index}
                  to={text.to}
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                >
                  <ListItemIcon className={classes.icon}>
                    {React.cloneElement(text.icon, {
                      color: selectedList === index ? "#091433" : "#fff",
                    })}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.title}
                    className={`${
                      selectedList === index ? classes.activeColor : ""
                    }`}
                  />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidedrawer;
