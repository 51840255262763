import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => {
  return {
    helperTextContent: {
      display: "list-item",
      listStyleType: "disc",
      listStylePosition: "outside",
      fontSize: "12px !important",
      margin: "3px 14px 0 14px !important",
    },
  };
});

const PasswordConstraint = () => {
  const classes = useStyles();
  return (
    <>
      <Typography component={"span"} className={classes.helperTextContent}>
        Minimum 8 characters long - the more, the better
      </Typography>
      <Typography component={"span"} className={classes.helperTextContent}>
        At least one lowercase & one uppercase character
      </Typography>
      <Typography component={"span"} className={classes.helperTextContent}>
        At least one number, symbol, or whitespace character
      </Typography>
    </>
  );
};

export default PasswordConstraint;
