import React from "react";

const LawFirmIcon = ({ color = "#091433" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M5.52501 13.5H3.61252C2.54002 13.5 2 12.96 2 11.8875V3.11252C2 2.04002 2.54002 1.5 3.61252 1.5H6.83751C7.91001 1.5 8.44998 2.04002 8.44998 3.11252V4.5"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5274 6.31499V14.685C13.5274 15.8925 12.9274 16.5 11.7199 16.5H7.33989C6.13239 16.5 5.5249 15.8925 5.5249 14.685V6.31499C5.5249 5.10749 6.13239 4.5 7.33989 4.5H11.7199C12.9274 4.5 13.5274 5.10749 13.5274 6.31499Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5498 4.5V3.11252C10.5498 2.04002 11.0898 1.5 12.1623 1.5H15.3873C16.4598 1.5 16.9998 2.04002 16.9998 3.11252V11.8875C16.9998 12.96 16.4598 13.5 15.3873 13.5H13.5273"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8.25H11"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.5H11"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 16.5V14.25"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LawFirmIcon;
