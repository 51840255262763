const Style = (theme) => ({
  root: {
    "& .MuiDataGrid-colCell": {
      borderRight: "none",
    },
  },
  profile__img__section: {
    width: "108px",
    height: "108px",
    position: "absolute",
    bottom: "-56px",
    left: "22px",
    borderRadius: "50%",
    "& .profile__img": {
      height: "100%",
      width: "100%",
      // position: "absolute",
      borderRadius: "50%",
    },
  },

  icon: {
    width: "100%",
    height: "100%",
    padding: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  // client__banner__container: {
  //   [theme.breakpoints.down("md")]: {
  //     "& .second__grid": {
  //       paddingLeft: "0",
  //       paddingTop: "2rem",
  //     },
  //   },
  // },
});
export default Style;
