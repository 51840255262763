import {
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGOUT,
} from "../../assets/constants/adminConstants";
import axios from "axios";
import { BASE_URL } from "..";
import Cookies from "js-cookie";

// ======================================== ADMIN LOG IN REDUCER ========================================
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        "Cache-Control": "no-cache",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/admin/login`,
      { email, password },
      config
    );

    const payloadData = {
      token: response?.data?.token,
      adminId: response?.data?.admin?.id,
    };

    dispatch({
      type: ADMIN_LOGIN_SUCCESS,
      payload: payloadData,
    });

    Cookies.set("token", response?.data?.token, { path: "/" });
    Cookies.set("adminId", response?.data?.admin?.id, { path: "/" });

  } catch (error) {
    dispatch({
      type: ADMIN_LOGIN_FAIL,
      payload:
        error?.response &&
        error?.response?.status &&
        error?.response?.status === 404
          ? error?.message
          : error?.response?.status === 401
          ? "Email or password is incorrect!"
          : error?.message,
    });
  }
};

// ================== ADMIN LOGOUT =====================
export const logout = () => async (dispatch) => {
  Cookies.remove("token");
  Cookies.remove("adminId");
  Cookies.remove("selectedIndex");
  dispatch({ type: ADMIN_LOGOUT });
};
