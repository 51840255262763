import React from "react";
import WarningImg from "../../assets/images/warning-img.png";
import { Box, Button, Stack, Typography } from "@mui/material";

const CrashScreen = () => {

  return (
    <Stack
      gap="16px"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      maxWidth="646px"
      width="100%"
      margin="0 auto"
      padding={2}
    >
      <Box width="120px" height="120px">
        <img
          src={WarningImg}
          alt="something went wrong"
          width="100%"
          height="100%"
        />
      </Box>
      <Typography variant="h1" fontWeight="500" fontSize="38px">
        Something went wrong!
      </Typography>
      <Typography variant="body1" fontSize="20px" textAlign="center">
        We apologize for the inconvenience. To better assist you and resolve
        this issue, please visit our{" "}
        <Box
          component="span"
          color="#0C80F3"
          sx={{ textDecoration: "underline" }}
        >
          {" "}
          <a target="_blank" href="https://accreteconcierge.atlassian.net/servicedesk/customer/portal/1">Help Center.</a>
        </Box>{" "}
      </Typography>
      <Button
        size="large"
        variant="contained"
        sx={{ textTransform: "initial", cursor: "pointer" }}
        href="/"
      >
        Take me to dashboard
      </Button>
    </Stack>
  );
};

export default CrashScreen;
