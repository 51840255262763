import { composeWithDevTools } from "@redux-devtools/extension";
import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import Cookies from "js-cookie";
import { userLoginReducer } from "../reducers/Auth";
import {
  changeEmailReducer,
  changePasswordReducer,
  getAdminProfileReducer,
  getAgencyDetailReducer,
  getLoginCredentialsReducer,
} from "../reducers/Admin";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  adminProfile: getAdminProfileReducer,
  getAgencyDetails: getAgencyDetailReducer,
  getLoginCredentials: getLoginCredentialsReducer,
  changeEmail: changeEmailReducer,
  changePassword: changePasswordReducer,
});

const token = Cookies.get("token") ?? null;
const adminId = Cookies.get("adminId") ?? null;

const initialState = {
  userLogin: {
    userInfo: { token, adminId },
  },
};

const middlewares = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
