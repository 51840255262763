import { Grid, Skeleton } from "@mui/material";
import React from "react";

const SkeletonGrid = ({ itemCount, height, md }) => {
  const renderSkeletonItems = () => {
    return Array.from({ length: itemCount }, (_, index) => (
      <Grid item md={md} key={index} pl={index === 0 && "0 !important"}>
        <Skeleton variant="rounded" height={height} animation="wave" />
      </Grid>
    ));
  };

  return (
    <Grid container spacing={3} width="100%">
      {renderSkeletonItems()}
    </Grid>
  );
};

export default SkeletonGrid;
