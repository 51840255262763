import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { LogoutIcon } from "../../assets/constants";
import { useNavigate } from "react-router-dom";
import HeaderText from "../../common/HeaderText";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/Auth";
import { getAdminProfile } from "../../actions/Admin";

const useStyles = makeStyles((theme) => {
  return {
    main__appbar: {
      background: "#fcfcfc !important",
      position: "fixed",
      top: "0",
      padding: "1rem 40px",
      left: "auto",
      display: "flex",
      justifyContent: "space-between",
      zIndex: "100",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        left: "0",
      },
    },
    bellIcon: {
      color: "#555",
    },
    menu_item: {
      padding: "16px !important",
    },
  };
});

const NavbarUI = ({ drawerWidth }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const adminProfile = useSelector((state) => state.adminProfile);
  const { loading, error, adminUser } = adminProfile;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = () => {
    setAnchorEl(null);
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    dispatch(getAdminProfile());
  }, []);

  return (
    <>
      <AppBar
        sx={{
          width: `calc(100% - ${drawerWidth})`,
        }}
        elevation={0}
        className={classes.main__appbar}
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <>
            <Stack sx={{ cursor: "pointer" }}>
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableRipple
              >
                <Avatar
                  alt="Profile Picture"
                  src={adminUser?.admin?.profile_picture_url}
                />
              </IconButton>
            </Stack>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="16px"
                  padding="10px 0"
                >
                  <Avatar
                    sx={{ width: 40, height: 40 }}
                    alt="Profile Picture"
                    src={adminUser?.admin?.profile_picture_url}
                  />

                  <Box display="flex" flexDirection="column" gap="1px">
                    <HeaderText
                      variant="h3"
                      fontSize="16px"
                      textContent={adminUser?.admin?.name}
                      textAlign="inherit"
                      color="#000"
                    />
                    <HeaderText
                      variant="h6"
                      fontSize="12px"
                      fontWeight="400"
                      textContent={adminUser?.admin?.email}
                      textAlign="inherit"
                    />
                  </Box>
                </Box>
              </MenuItem>

              <Divider />
              <MenuItem onClick={logoutHandler} className={classes.menu_item}>
                <ListItemIcon sx={{ marginRight: "10px" }}>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </>
        </Stack>
      </AppBar>
    </>
  );
};

export default NavbarUI;
