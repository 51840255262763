import { Typography } from "@mui/material";

const HeaderText = ({
  variant,
  fontSize = "35px",
  textContent,
  fontWeight = "500",
  lineHeight = "normal",
  textAlign = "-webkit-auto",
  color,
}) => {
  return (
    <Typography
      variant={variant}
      color={color && color}
      textAlign={textAlign}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
    >
      {textContent}
    </Typography>
  );
};

export default HeaderText;
