import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

const SkeletonUI = () => {
  return (
    <Box
      border="1px dashed #566875"
      borderRadius="8px"
      padding="10px 32px"
      minHeight="186px"
      display="flex"
      alignItems="center"
      gap="32px"
    >
      <Skeleton variant="rounded" width={112} height={112} />
      <Stack>
        <Skeleton variant="text" width={350} sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" width={350} sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" width={350} sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" width={350} sx={{ fontSize: "1rem" }} />
      </Stack>
    </Box>
  );
};

export default SkeletonUI;
