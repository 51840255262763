import {
  CHANGE_EMAIL_FAIL,
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  GET_ADMIN_PROFILE_FAIL,
  GET_ADMIN_PROFILE_REQUEST,
  GET_ADMIN_PROFILE_SUCCESS,
  GET_AGENCY_DETAIL_FAIL,
  GET_AGENCY_DETAIL_REQUEST,
  GET_AGENCY_DETAIL_SUCCESS,
  GET_LOGIN_CREDENTIALS_FAIL,
  GET_LOGIN_CREDENTIALS_REQUEST,
  GET_LOGIN_CREDENTIALS_SUCCESS,
  RESET_AGENCY_DETAIL,
  RESET_CHANGE_EMAIL,
  RESET_CHANGE_PASSWORD,
  RESET_LOGIN_CREDENTIALS,
} from "../../assets/constants/adminConstants";

// ADMIN PROFILE REDUCER
export const getAdminProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ADMIN_PROFILE_REQUEST:
      return { loading: true };
    case GET_ADMIN_PROFILE_SUCCESS:
      return { loading: false, adminUser: action.payload };
    case GET_ADMIN_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET AGENCY DETAIL REDUCER
export const getAgencyDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AGENCY_DETAIL_REQUEST:
      return { loading: true };
    case GET_AGENCY_DETAIL_SUCCESS:
      return { loading: false, agencyDetails: action.payload };
    case GET_AGENCY_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case RESET_AGENCY_DETAIL:
      return {};
    default:
      return state;
  }
};

// GET AGENCY DETAIL REDUCER
export const getLoginCredentialsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LOGIN_CREDENTIALS_REQUEST:
      return { loading: true };
    case GET_LOGIN_CREDENTIALS_SUCCESS:
      return { loading: false, loginCredentialsDetails: action.payload };
    case GET_LOGIN_CREDENTIALS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_LOGIN_CREDENTIALS:
      return {};
    default:
      return state;
  }
};

// CHANGE EMAIL REDUCER
export const changeEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_EMAIL_REQUEST:
      return { loading: true };
    case CHANGE_EMAIL_SUCCESS:
      return { loading: false, changedEmail: action.payload };
    case CHANGE_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CHANGE_EMAIL:
      return {};
    default:
      return state;
  }
};

// CHANGE PASSWORD REDUCER
export const changePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return { loading: true };
    case CHANGE_PASSWORD_SUCCESS:
      return { loading: false, changedPassword: action.payload };
    case CHANGE_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CHANGE_PASSWORD:
      return {};
    default:
      return state;
  }
};
