import { IconButton, Menu, MenuItem, Stack } from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "../../icons/MoreVertIcon";

const menuItems = [
  { title: "Get Credentials", value: "getCredentials" },
  { title: "Change Email/Password", value: "changeEmail" },
];

const CustomMenuBox = ({ params, getPasswordModalHandler }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map((menuItem) => [
          <MenuItem
            key={menuItem.value}
            onClick={() => {
              getPasswordModalHandler(params.row, menuItem.value);
              setAnchorEl(null);
            }}
          >
            {menuItem.title}
          </MenuItem>,
        ])}
      </Menu>
    </>
  );
};

export default CustomMenuBox;
