import React, { useState } from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import KeyCahinImg from "../../assets/images/keyChainImg.png";
import CopyIcon from "../../icons/CopyIcon";
import SkeletonUI from "../SkeletonUI";
import { dateTimeFormat } from "../../utils/helpers";
import ChangEmailPasswordTab from "../ChangeEmailPasswordTab";

const LogincredentialsUI = ({
  loginCredentials,
  loadingCredential,
  setCopied,
  menuItem,
  tabValue,
  setTabValue,
  newEmail,
  setNewEmail,
  emailError,
  setEmailError,
  password,
  setPassword,
  confirmedNewPassword,
  setConfirmedNewPassword,
  lawFirmName,
  pictureUrl,
  ownerName,
  emailAddress,
  fromLawFirmListingPage = true,
}) => {
  const passwordCopyHandler = () => {
    navigator.clipboard.writeText(loginCredentials?.password);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const emailCopyHandler = () => {
    navigator.clipboard.writeText(loginCredentials?.email);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Stack gap="32px">
      <Box display="flex" alignItems="center">
        <Stack gap="4px" width="50%">
          <Typography variant="subtitle2" fontWeight="400" color="secondary">
            Law firm
          </Typography>
          <Typography variant="body1">{lawFirmName}</Typography>
        </Stack>
        <Stack gap="4px" width="50%">
          <Typography variant="subtitle2" fontWeight="400" color="secondary">
            Owner
          </Typography>
          <Box display="flex" alignItems="center" gap="8px">
            <Avatar src={pictureUrl} />
            <Stack>
              <Typography variant="body1">{ownerName}</Typography>
              <Typography variant="body2" fontWeight="400" color="secondary">
                {emailAddress}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Box>

      {menuItem === "changeEmail" && (
        <ChangEmailPasswordTab
          tabValue={tabValue}
          setTabValue={setTabValue}
          newEmail={newEmail}
          setNewEmail={setNewEmail}
          emailError={emailError}
          setEmailError={setEmailError}
          password={password}
          setPassword={setPassword}
          confirmedNewPassword={confirmedNewPassword}
          setConfirmedNewPassword={setConfirmedNewPassword}
          fromLawFirmListingPage={fromLawFirmListingPage}
        />
      )}

      {menuItem === "getCredentials" &&
        (loadingCredential ? (
          <SkeletonUI />
        ) : (
          <Box
            border="1px dashed #566875"
            borderRadius="8px"
            padding="10px 32px"
            minHeight="186px"
            display="flex"
            alignItems="center"
            gap="32px"
          >
            <Box>
              <img src={KeyCahinImg} alt="get login credentials" />
            </Box>
            <Stack gap="10px">
              <Typography variant="body1" color="secondary">
                Please use the provided credentials to log in
              </Typography>
              <Stack>
                <Box display="flex" alignItems="center" gap="8px">
                  <Typography variant="body1" color="#0D0D0D" fontWeight="500">
                    Email ID : {loginCredentials?.email}
                  </Typography>
                  <Box sx={{ cursor: "pointer" }} onClick={emailCopyHandler}>
                    <CopyIcon />
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" gap="8px">
                  <Typography variant="body1" color="#0D0D0D" fontWeight="500">
                    Password: {loginCredentials?.password}
                  </Typography>
                  <Box sx={{ cursor: "pointer" }} onClick={passwordCopyHandler}>
                    <CopyIcon />
                  </Box>
                </Box>
              </Stack>
              <Typography variant="body1" color="secondary">
                These Credentails are valid till{" "}
                {dateTimeFormat(loginCredentials?.access_expiry_at)}
              </Typography>
            </Stack>
          </Box>
        ))}
    </Stack>
  );
};

export default LogincredentialsUI;
