export const GET_ADMIN_PROFILE_REQUEST = "GET_ADMIN_PROFILE_REQUEST";
export const GET_ADMIN_PROFILE_SUCCESS = "GET_ADMIN_PROFILE_SUCCESS";
export const GET_ADMIN_PROFILE_FAIL = "GET_ADMIN_PROFILE_FAIL";
export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

export const GET_AGENCY_DETAIL_REQUEST = "GET_AGENCY_DETAIL_REQUEST";
export const GET_AGENCY_DETAIL_SUCCESS = "GET_AGENCY_DETAIL_SUCCESS";
export const GET_AGENCY_DETAIL_FAIL = "GET_AGENCY_DETAIL_FAIL";
export const RESET_AGENCY_DETAIL = "RESET_AGENCY_DETAIL";

export const GET_LOGIN_CREDENTIALS_REQUEST = "GET_LOGIN_CREDENTIALS_REQUEST";
export const GET_LOGIN_CREDENTIALS_SUCCESS = "GET_LOGIN_CREDENTIALS_SUCCESS";
export const GET_LOGIN_CREDENTIALS_FAIL = "GET_LOGIN_CREDENTIALS_FAIL";
export const RESET_LOGIN_CREDENTIALS = "RESET_LOGIN_CREDENTIALS";

export const CHANGE_EMAIL_REQUEST = "CHANGE_EMAIL_REQUEST";
export const CHANGE_EMAIL_SUCCESS = "CHANGE_EMAIL_SUCCESS";
export const CHANGE_EMAIL_FAIL = "CHANGE_EMAIL_FAIL";
export const RESET_CHANGE_EMAIL = "RESET_CHANGE_EMAIL";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const RESET_CHANGE_PASSWORD = "RESET_CHANGE_PASSWORD";
