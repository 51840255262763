import {
  Alert,
  Box,
  Button,
  Paper,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ArrowLeftIcon } from "../../assets/constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OverviewTab from "../../components/OverviewTab";
import BillingTab from "../../components/BillingTab";
import SettingsTab from "../../components/SettingsTab";
import { useDispatch, useSelector } from "react-redux";
import {
  changeEmailFunc,
  changePasswordFunc,
  getAgencyDetailFunc,
  getLoginCredentialsFunc,
  resetAgencyDetail,
  resetChangeEmail,
  resetChangePassword,
} from "../../actions/Admin";
import RectangleSkeletons from "../../components/RectangleSkeletons";
import AlertDialog from "../../components/AlertDialog";
import LogincredentialsUI from "../../components/LoginCredentialsUI";
import AlertMessage from "../../common/AlertMessage";

const tabsData = [
  {
    label: "Overview",
    value: "overview",
  },
  {
    label: "Billing",
    value: "billing",
  },
  {
    label: "Settings",
    value: "settings",
  },
];

const LawfirmDetail = () => {
  const navigate = useNavigate();

  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [modalContentData, setModalContentData] = useState({});
  const [menuItem, setMenuItem] = useState("");
  const [copied, setCopied] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedNewPassword, setConfirmedNewPassword] = useState("");
  const [emailError, setEmailError] = useState(false);

  const getAgencyDetails = useSelector((state) => state.getAgencyDetails);
  const { loading, error, agencyDetails } = getAgencyDetails;

  const getLoginCredentials = useSelector((state) => state.getLoginCredentials);
  const {
    loading: loadingCredential,
    error: errorCredential,
    loginCredentialsDetails,
  } = getLoginCredentials;

  const changeEmail = useSelector((state) => state.changeEmail);
  const {
    loading: loadingChangeEmail,
    error: errorChangeEmail,
    changedEmail,
  } = changeEmail;

  const changePassword = useSelector((state) => state.changePassword);
  const {
    loading: loadingchangePassword,
    error: errorchangePassword,
    changedPassword,
  } = changePassword;

  // const [agencyDetail, setAgencyDetail] = useState

  const { id } = useParams();
  const dispatch = useDispatch();

  const [lawFirmTabValue, setLawFirmTabValue] = useState("overview");
  const [changeEmailTabValue, setChangeEmailTabValue] = useState("changeEmail");

  const changeTabHandler = (event, newValue) => {
    setLawFirmTabValue(newValue);
  };

  const closePasswordModalHandler = () => {
    setOpenPasswordModal(false);
    setPassword("");
    setConfirmedNewPassword("");
    setNewEmail("");
    setEmailError(false);
  };

  useEffect(() => {
    dispatch(getAgencyDetailFunc(id));
  }, [id]);

  useEffect(() => {
    if (agencyDetails?.ok) {
      // dispatch(resetAgencyDetail());
    }
  }, [agencyDetails]);

  const getPasswordModalHandler = () => {
    setMenuItem("getCredentials");
    // setRowData(agencyDetail);
    setOpenPasswordModal(true);
    setModalContentData({
      title: "Login Credentials",
    });

    dispatch(getLoginCredentialsFunc(id));
  };

  useEffect(() => {
    if (loginCredentialsDetails?.ok) {
    }
  }, [loginCredentialsDetails]);

  const changeEmailPasswordHandler = (e) => {
    // closePasswordModalHandler();
    if (changeEmailTabValue === "changeEmail") {
      // CHANGE EMAIL HANDLER

      const emailObj = {
        agency_id: agencyDetails?.agency?.id,
        agent_id: agencyDetails?.agency?.owner?.id,
        email: newEmail,
      };

      dispatch(changeEmailFunc(emailObj));
    } else {
      // CHANGE PASSWORD HANDLER
      // changePasswordApiHandler();
      const passwordObj = {
        agency_id: agencyDetails?.agency?.id,
        agent_id: agencyDetails?.agency?.owner?.id,
        password: password,
      };
      dispatch(changePasswordFunc(passwordObj));
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  useEffect(() => {
    if (changedEmail?.ok) {
      setOpenAlert(true);
      setSuccessMessage("Email changed successfully!");
      setOpenPasswordModal(false);
      dispatch(getAgencyDetailFunc(id));
      setNewEmail("");
      dispatch(resetChangeEmail());
    } else if (errorChangeEmail) {
      setOpenAlert(true);
    }
  }, [changedEmail, errorChangeEmail]);

  useEffect(() => {
    if (changedPassword?.ok) {
      setOpenAlert(true);
      setSuccessMessage("Password changed successfully!");
      setOpenPasswordModal(false);
      setPassword("");
      setConfirmedNewPassword("");
      dispatch(resetChangePassword());
    } else if (errorchangePassword) {
      setOpenAlert(true);
    }
  }, [changedPassword, errorchangePassword]);

  const getChangeEmailPasswordText = () => {
    if (changeEmailTabValue === "changeEmail") {
      return "Change Email";
    } else {
      return "Change Password";
    }
  };

  const changeEmailHandler = () => {
    setOpenPasswordModal(true);
    setMenuItem("changeEmail");
    setChangeEmailTabValue("changeEmail");
  };

  const changePasswordHandler = () => {
    setOpenPasswordModal(true);
    setMenuItem("changeEmail");
    setChangeEmailTabValue("changePassword");
  };

  const submitText =
    menuItem === "getCredentials" ? "Done" : getChangeEmailPasswordText();

  return (
    <>
      {copied && (
        <Snackbar
          open={copied}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            severity="success"
            color="info"
            sx={{ zIndex: 1400, width: "200px", margin: "0 auto" }}
          >
            Text Copied!
          </Alert>
        </Snackbar>
      )}

      {openAlert && (
        <AlertMessage
          message={successMessage}
          severity="success"
          open={openAlert}
          handleClose={handleClose}
        />
      )}

      {errorchangePassword && (
        <AlertMessage
          message={errorchangePassword}
          severity="error"
          open={openAlert}
          handleClose={handleClose}
        />
      )}

      {errorChangeEmail && (
        <AlertMessage
          message={errorChangeEmail}
          severity="error"
          open={openAlert}
          handleClose={handleClose}
        />
      )}

      {openPasswordModal && (
        <>
          <AlertDialog
            data={modalContentData}
            submitText={submitText}
            isLoading={
              loadingCredential || loadingChangeEmail || loadingchangePassword
            }
            openModal={openPasswordModal}
            closeModal={closePasswordModalHandler}
            formSubmitFunction={
              menuItem === "getCredentials"
                ? closePasswordModalHandler
                : changeEmailPasswordHandler
            }
            maxWidth="727px"
            height={
              menuItem === "changeEmail"
                ? changeEmailTabValue === "changeEmail"
                  ? "495px"
                  : "727px"
                : "auto"
            }
            disabled={
              menuItem === "getCredentials"
                ? false
                : changeEmailTabValue === "changeEmail"
                ? !newEmail.length || emailError
                : !password.length || password !== confirmedNewPassword
            }
          >
            <LogincredentialsUI
              lawFirmName={agencyDetails?.agency?.name}
              pictureUrl={agencyDetails?.agency?.owner?.profile_picture_url}
              ownerName={agencyDetails?.agency?.owner?.name}
              emailAddress={
                agencyDetails?.agency?.owner?.email
                  ? agencyDetails?.agency?.owner?.email
                  : "-"
              }
              menuItem={menuItem}
              loginCredentials={loginCredentialsDetails}
              loadingCredential={loadingCredential}
              setCopied={setCopied}
              tabValue={changeEmailTabValue}
              setTabValue={setChangeEmailTabValue}
              newEmail={newEmail}
              setNewEmail={setNewEmail}
              emailError={emailError}
              setEmailError={setEmailError}
              password={password}
              setPassword={setPassword}
              confirmedNewPassword={confirmedNewPassword}
              setConfirmedNewPassword={setConfirmedNewPassword}
              fromLawFirmListingPage={false}
            />
          </AlertDialog>
        </>
      )}

      <Stack gap="16px">
        <Stack gap="8px">
          <Box
            display="flex"
            alignSelf="flex-start"
            gap="8px"
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding="2px"
              width="20px"
              height="20px"
              bgcolor="#fff"
              boxShadow=" 0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
              borderRadius="50%"
              sx={{ cursor: "pointer" }}
            >
              <ArrowLeftIcon />
            </Box>
            <Typography variant="body2">Back To List</Typography>
          </Box>
          {loading ? (
            <RectangleSkeletons height={40} totalRows={1} width={300} />
          ) : (
            <Typography variant="h5" fontSize="30px" fontWeight={500}>
              {agencyDetails?.agency?.name}
            </Typography>
          )}
          <Box display="flex" alignItems="center" gap="8px">
            <Button
              variant="contained"
              disableElevation
              sx={{ color: "#0D0D0D", background: "#F4F6F8" }}
              onClick={getPasswordModalHandler}
            >
              GET SUPPORT CREDENTIALS
            </Button>
            <Button
              variant="contained"
              disableElevation
              sx={{ color: "#0D0D0D", background: "#F4F6F8" }}
              onClick={changePasswordHandler}
            >
              CHANGE PASSWORD{" "}
            </Button>
            <Button
              variant="contained"
              disableElevation
              sx={{ color: "#0D0D0D", background: "#F4F6F8" }}
              onClick={changeEmailHandler}
            >
              CHANGE EMAIL{" "}
            </Button>
          </Box>
        </Stack>
        <Stack gap="24px">
          {/* TABS BOX */}
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={lawFirmTabValue}
              onChange={changeTabHandler}
              sx={{ minHeight: "0 !important" }}
              indicatorColor="primary"
              // textColor="secondary"
              // aria-label="secondary tabs example"
            >
              {tabsData.map((tabData, i) => (
                <Tab
                  sx={{
                    minHeight: "0 !important",
                    "& svg": {
                      marginRight: "8px !important",
                    },
                  }}
                  key={i}
                  value={tabData.value}
                  label={tabData.label}
                  disabled={
                    tabData.label === "Billing" || tabData.label === "Settings"
                  }
                  //   to={`/case/${id}?tab=${tabData.value}`}
                  // to={`/case/${id}?tab=${tabData.value}`}
                />
              ))}
            </Tabs>
          </Box>

          {lawFirmTabValue === "overview" && (
            <OverviewTab agencyDetails={agencyDetails} />
          )}
          {lawFirmTabValue === "billing" && <BillingTab />}
          {lawFirmTabValue === "settings" && <SettingsTab />}
        </Stack>
      </Stack>
    </>
  );
};

export default LawfirmDetail;
