import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import PasswordConstraint from "../PasswordConstraint";
import { validateEmail } from "../../utils/helpers";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const tabsData = [
  {
    label: "Change Email",
    value: "changeEmail",
  },
  {
    label: "Change Password",
    value: "changePassword",
  },
];

const ChangEmailPasswordTab = ({
  tabValue,
  setTabValue,
  newEmail,
  setNewEmail,
  emailError,
  setEmailError,
  password,
  setPassword,
  confirmedNewPassword,
  setConfirmedNewPassword,
  fromLawFirmListingPage,
}) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmedNewPassword, setShowConfirmedNewPassword] =
    useState(false);
  const [validPassword, setValidPassword] = useState(true);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const newPasswordChangeHandler = (e) => {
    const newPswd = e.target.value;
    setPassword(newPswd);

    setValidPassword(passwordRegex.test(newPswd));
  };

  const handleClickShowPassword = (password_type) => {
    if (password_type == "new") {
      setShowNewPassword((show) => !show);
    } else if (password_type == "confirmed_new") {
      setShowConfirmedNewPassword((show) => !show);
    }
  };

  const emailChangeHandler = (e) => {
    const emailInputValue = e.target.value;

    setNewEmail(emailInputValue);

    const emailIsValid = validateEmail(emailInputValue);

    if (!emailIsValid) {
      setEmailError(true);
      return;
    }

    setEmailError(false);
  };

  return (
    <>
      {fromLawFirmListingPage && (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={(_, newValue) => setTabValue(newValue)}
            aria-label="change-email-password"
          >
            {tabsData.map((tabData, i) => (
              <Tab
                sx={{
                  minHeight: "0 !important",
                  "& svg": {
                    marginRight: "8px !important",
                  },
                }}
                key={i}
                value={tabData.value}
                label={tabData.label}
              />
            ))}
          </Tabs>
        </Box>
      )}
      {tabValue === "changeEmail" && (
        <Box>
          <TextField
            label="New Email"
            value={newEmail}
            error={emailError}
            helperText={emailError && "Please enter valid email!"}
            onChange={emailChangeHandler}
            fullWidth
          />
        </Box>
      )}
      {tabValue === "changePassword" && (
        <Stack gap="32px">
          <TextField
            placeholder="Enter Password"
            margin="dense"
            label="New Password"
            error={!validPassword}
            helperText={
              (!validPassword &&
                "Password must be a combination of uppercase and lowercase characters, numbers and special characters(!, @, $, %, &, *, ?)") || (
                <PasswordConstraint />
              )
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("new")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={password}
            name="password"
            onChange={newPasswordChangeHandler}
            type={showNewPassword ? "text" : "password"}
          />
          <TextField
            placeholder="Enter Password"
            margin="dense"
            label="Confirm New Password"
            error={password !== confirmedNewPassword}
            helperText={
              password !== confirmedNewPassword && "Password does not matched"
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("confirmed_new")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmedNewPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={confirmedNewPassword}
            name="confirmedNewPassword"
            onChange={(e) => setConfirmedNewPassword(e.target.value)}
            type={showConfirmedNewPassword ? "text" : "password"}
          />
        </Stack>
      )}
    </>
  );
};

export default ChangEmailPasswordTab;
