// import { Provider } from 'react-redux';
import { ThemeProvider } from "@mui/material/styles";

import MainRoutes from "./routes";
// import store from './store/index';
import theme from "./theme/theme";

import "./assets/scss/index.scss";

const App = () => (
  // <Provider store={store}>
  <ThemeProvider theme={theme}>
    <div id="portal" />
    <MainRoutes />
  </ThemeProvider>
  // </Provider>
);

export default App;
