import axios from "axios";
import { BASE_URL } from "..";
import {
  CHANGE_EMAIL_FAIL,
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  GET_ADMIN_PROFILE_FAIL,
  GET_ADMIN_PROFILE_REQUEST,
  GET_ADMIN_PROFILE_SUCCESS,
  GET_AGENCY_DETAIL_FAIL,
  GET_AGENCY_DETAIL_REQUEST,
  GET_AGENCY_DETAIL_SUCCESS,
  GET_LOGIN_CREDENTIALS_FAIL,
  GET_LOGIN_CREDENTIALS_REQUEST,
  GET_LOGIN_CREDENTIALS_SUCCESS,
  RESET_AGENCY_DETAIL,
  RESET_CHANGE_EMAIL,
  RESET_CHANGE_PASSWORD,
  RESET_LOGIN_CREDENTIALS,
} from "../../assets/constants/adminConstants";
import Cookies from "js-cookie";

// ================== GET ADMIN USER PROFILE =========================
export const getAdminProfile = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ADMIN_PROFILE_REQUEST });

    // const {
    //   userLogin: { userInfo },
    // } = getState();

    // var userInfo = localStorage.getItem("userInfo");
    const token = Cookies.get("token");
    const adminId = Cookies.get("adminId");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/admin/get-admin-user?admin_user_id=${adminId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: GET_ADMIN_PROFILE_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: GET_ADMIN_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET AGENCY DETAILS ACTION =========================
export const getAgencyDetailFunc = (agencyId) => async (dispatch) => {
  try {
    dispatch({ type: GET_AGENCY_DETAIL_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/admin/get-agency?agency_id=${agencyId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_AGENCY_DETAIL_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_AGENCY_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetAgencyDetail = () => (dispatch) =>
  dispatch({
    type: RESET_AGENCY_DETAIL,
  });

// ================== GET LOGIN CREDENTIALS DETAILS ACTION =========================
export const getLoginCredentialsFunc = (agencyId) => async (dispatch) => {
  try {
    dispatch({ type: GET_LOGIN_CREDENTIALS_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/admin/get-login-credentials?agency_id=${agencyId}`,
        config
      );

      dispatch({
        type: GET_LOGIN_CREDENTIALS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_LOGIN_CREDENTIALS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetLoginCredentials = () => (dispatch) =>
  dispatch({
    type: RESET_LOGIN_CREDENTIALS,
  });

// ================== CHANGE EMAIL  ACTION =========================
export const changeEmailFunc = (emailObj) => async (dispatch) => {
  try {
    dispatch({ type: CHANGE_EMAIL_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/update-agent-email`,
        emailObj,
        config
      );

      dispatch({
        type: CHANGE_EMAIL_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CHANGE_EMAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetChangeEmail = () => (dispatch) =>
  dispatch({ type: RESET_CHANGE_EMAIL });

// ================== CHANGE EMAIL  ACTION =========================
export const changePasswordFunc = (passwordObj) => async (dispatch) => {
  try {
    dispatch({ type: CHANGE_PASSWORD_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/update-agent-password`,
        passwordObj,
        config
      );

      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CHANGE_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetChangePassword = () => (dispatch) =>
  dispatch({ type: RESET_CHANGE_PASSWORD });
