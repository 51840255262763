import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Lawfirms from "../pages/Lawfirms";
import CircularProgressLoading from "../common/CircularProgress";
import { useSelector } from "react-redux";
import { getMyValue } from "../utils/helpers";
import ProtectedRoute from "../ProtectedRoute";
import LawfirmDetail from "../pages/LawfirmDetail";

const Signin = lazy(() => import("../pages/Signin"));

const MainRouts = () => {
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;
  const [cookieValues, setCookieValues] = useState({
    token: "",
    adminId: "",
  });

  useEffect(() => {
    setCookieValues((prevState) => ({
      ...prevState,
      token: getMyValue(userInfo?.token),
      adminId: getMyValue(userInfo?.adminId),
    }));
  }, [userInfo]);

  const isAuthenticated = cookieValues?.token && cookieValues?.adminId;

  return (
    <BrowserRouter>
      <Suspense fallback={<CircularProgressLoading />}>
        <Routes>
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route exact path="/" element={<></>} />
            <Route exact path="/lawfirms" element={<Lawfirms />} />
            <Route exact path="/lawfirm/:id" element={<LawfirmDetail />} />
          </Route>

          <Route
            path="/signin"
            element={isAuthenticated ? <Navigate to="/lawfirms" /> : <Signin />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
export default MainRouts;
