import React, { Fragment } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import AppLayout from "../components/AppLayout";

// const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => (
//   <Fragment>
//     {isAuthenticated ? <Component {...rest} /> : <Navigate to="/signin" />}
//   </Fragment>
// );

const ProtectedRoute = ({ isAuthenticated, children }) => {
  if (!isAuthenticated && isAuthenticated !== "") {
    return <Navigate to={"/signin"} />;
  }

  return children ? children : <AppLayout />;
};

export default ProtectedRoute;
