import React from "react";

const MoreVertIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="18"
      viewBox="0 0 5 18"
      fill="none"
    >
      <path
        d="M2.5 4.5C3.7375 4.5 4.75 3.4875 4.75 2.25C4.75 1.0125 3.7375 0 2.5 0C1.2625 0 0.25 1.0125 0.25 2.25C0.25 3.4875 1.2625 4.5 2.5 4.5ZM2.5 6.75C1.2625 6.75 0.25 7.7625 0.25 9C0.25 10.2375 1.2625 11.25 2.5 11.25C3.7375 11.25 4.75 10.2375 4.75 9C4.75 7.7625 3.7375 6.75 2.5 6.75ZM2.5 13.5C1.2625 13.5 0.25 14.5125 0.25 15.75C0.25 16.9875 1.2625 18 2.5 18C3.7375 18 4.75 16.9875 4.75 15.75C4.75 14.5125 3.7375 13.5 2.5 13.5Z"
        fill="black"
        fillOpacity="0.56"
      />
    </svg>
  );
};

export default MoreVertIcon;
