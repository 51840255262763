import { Skeleton, Stack } from "@mui/material";
import React from "react";

const RectangleSkeletons = ({
  height = 67,
  totalRows = 3,
  gap = "8px",
  width = "100%",
}) => {
  const skeletons = [];

  for (let i = 0; i < totalRows; i++) {
    skeletons.push(
      <Skeleton key={i} variant="rounded" width={width} height={height} />
    );
  }

  return (
    <Stack gap={gap} width="100%">
      {skeletons}
    </Stack>
  );
};

export default RectangleSkeletons;
