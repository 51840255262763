import dayjs from "dayjs";

export function getMyValue(value) {
  if (value === null || value === undefined || value === "null") {
    return;
  }
  return value;
}

export const dateTimeFormat = (dateTimeString) => {
  const formattedDateTime = dayjs(dateTimeString).format("hh:mm A, D MMMM");
  return formattedDateTime;
};

export const validateEmail = (inputEmail) => {
  const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return pattern.test(inputEmail);
};
