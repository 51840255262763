import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import {
  Stack,
  Typography,
  Box,
  Avatar,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Alert,
  Snackbar,
  Grid,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Style from "./style";
import CircularProgressLoading from "../../common/CircularProgress";
import CardUI from "../../common/Card";
import { DataGrid } from "@mui/x-data-grid";
import AlertDialog from "../../components/AlertDialog";
import AvatarWithName from "../../components/AvatarWithName";
import HeaderText from "../../common/HeaderText";
import Cookies from "js-cookie";
import EmptyBox from "../../assets/images/empty-box.png";
import AlertMessage from "../../common/AlertMessage";
import LogincredentialsUI from "../../components/LoginCredentialsUI";
import LawFirmCard from "../../components/LawFirmCard";
import buildingImg from "../../assets/images/buildingImg.png";
import folderImg from "../../assets/images/folderImg.png";
import moneyImg from "../../assets/images/moneyImg.png";
import SkeletonGrid from "../../components/SkeletonGrid";
import CustomMenuBox from "../../components/CustomMenuBox";
import { useNavigate } from "react-router-dom";
import {
  changeEmailFunc,
  changePasswordFunc,
  getLoginCredentialsFunc,
  resetChangeEmail,
  resetChangePassword,
  resetLoginCredentials,
} from "../../actions/Admin";
import { useDispatch, useSelector } from "react-redux";

const Styles = makeStyles((theme) => Style(theme));

export default function Lawfirms() {
  const classes = Styles();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const [modalContentData, setModalContentData] = useState({});
  const [submitDeleteID, setSubmitDeleteID] = useState(null);
  const [rowData, setRowData] = useState({});
  const [menuItem, setMenuItem] = useState("");
  const [loginCredentials, setLoginCredentials] = useState(null);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  // const [loadingCredential, setLoadingCredential] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agenciesList, setAgenciesList] = useState([]);
  const [lawFirmStats, setLawFirmStats] = useState([]);
  const [loadingStats, setLoadingStats] = useState(false);
  const [copied, setCopied] = useState(false);
  const [tabValue, setTabValue] = useState("changeEmail");
  const [newEmail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedNewPassword, setConfirmedNewPassword] = useState("");
  const [emailError, setEmailError] = useState(false);

  const getLoginCredentials = useSelector((state) => state.getLoginCredentials);
  const {
    loading: loadingCredential,
    error: errorCredential,
    loginCredentialsDetails,
  } = getLoginCredentials;

  const changeEmail = useSelector((state) => state.changeEmail);
  const {
    loading: loadingChangeEmail,
    error: errorChangeEmail,
    changedEmail,
  } = changeEmail;

  const changePassword = useSelector((state) => state.changePassword);
  const {
    loading: loadingchangePassword,
    error: errorchangePassword,
    changedPassword,
  } = changePassword;

  // const modalOpenHandler = () => {
  //   setOpenModal(true);
  // };

  const modalCloseHandler = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    getAgenciesList();
    getLawFirmStats();
  }, []);

  const getAgenciesList = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/get-agencies`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error("Something went wrong!");
      }

      setAgenciesList(response?.data?.agencies);
      setLoading(false);
    } catch (error) {
      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      setLoading(false);
      console.log(error);
    }
  };

  // GET LAW FIRM STATS
  const getLawFirmStats = async () => {
    try {
      setLoadingStats(true);
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/get-stats`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error("Something went wrong!");
      }

      setLawFirmStats(response?.data);
      setLoadingStats(false);
    } catch (error) {
      setError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      setLoadingStats(false);
      console.log(error);
    }
  };

  // GET LOGIN CREDENTIALS
  // const getLoginCredential = async (agencyDetail) => {
  //   try {
  //     setLoadingCredential(true);
  //     const config = {
  //       headers: {
  //         "Content-type": "application/json",
  //         Authorization: `Bearer ${Cookies.get("token")}`,
  //       },
  //     };
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/admin/get-login-credentials?agency_id=${agencyDetail?.id}`,
  //       config
  //     );

  //     if (!response?.data?.ok) {
  //       throw new Error("Something went wrong!");
  //     }

  //     setLoginCredentials(response?.data);
  //     setLoadingCredential(false);
  //   } catch (error) {
  //     setError(
  //       error?.response && error?.response?.data?.message
  //         ? error?.response?.data?.message
  //         : error?.message
  //     );
  //     setLoginCredentials(null);
  //     setLoadingCredential(false);
  //     setOpenAlert(true);
  //   }
  // };

  const [query, setQuery] = useState("");
  const regex = new RegExp(query, "i");

  const getPasswordModalHandler = (agencyDetail, menuItem) => {
    setMenuItem(menuItem);
    setRowData(agencyDetail);
    setOpenPasswordModal(true);
    setModalContentData({
      title: "Login Credentials",
    });

    if (menuItem === "getCredentials") {
      dispatch(getLoginCredentialsFunc(agencyDetail?.id));
      // getLoginCredential(agencyDetail);
    }
  };

  useEffect(() => {
    if (loginCredentialsDetails?.ok) {
      setLoginCredentials(loginCredentialsDetails);
      // dispatch(resetLoginCredentials());
    } else if (errorCredential) {
      setOpenAlert(true);
    }
  }, [loginCredentialsDetails, errorCredential]);

  const closePasswordModalHandler = () => {
    setOpenPasswordModal(false);
  };

  const getChangeEmailPasswordText = () => {
    if (tabValue === "changeEmail") {
      return "Change Email";
    } else {
      return "Change Password";
    }
  };

  // CHANGE PASSWORD HANDLER
  // const changePasswordApiHandler = async () => {
  //   try {
  //     setLoading(true);

  //     const config = {
  //       headers: {
  //         "Content-type": "application/json",
  //         Authorization: `Bearer ${Cookies.get("token")}`,
  //       },
  //     };

  //     const passwordObj = {
  //       agency_id: rowData?.id,
  //       agent_id: rowData?.owner_with_picture?.agentId,
  //       password: password,
  //     };

  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/admin/update-agent-password`,
  //       passwordObj,
  //       config
  //     );

  //     if (!response?.data?.ok) {
  //       throw new Error("Error while changing password!");
  //     }

  //     setLoading(false);
  //     setOpenAlert(true);
  //     setSuccessMessage("Password changed successfully!");
  //     setOpenPasswordModal(false);
  //     setPassword("");
  //     setConfirmedNewPassword("");
  //   } catch (error) {
  //     setLoading(false);
  //     setError(
  //       error?.response && error?.response?.data?.message
  //         ? error?.response?.data?.message
  //         : error?.message
  //     );
  //   }
  // };

  const changeEmailPasswordHandler = (e) => {
    if (tabValue === "changeEmail") {
      // CHANGE EMAIL HANDLER

      const emailObj = {
        agency_id: rowData?.id,
        agent_id: rowData?.owner_with_picture?.agentId,
        email: newEmail,
      };
      dispatch(changeEmailFunc(emailObj));
    } else {
      // CHANGE PASSWORD HANDLER
      const passwordObj = {
        agency_id: rowData?.id,
        agent_id: rowData?.owner_with_picture?.agentId,
        password: password,
      };
      dispatch(changePasswordFunc(passwordObj));
    }
  };

  useEffect(() => {
    if (changedEmail?.ok) {
      setOpenAlert(true);
      setSuccessMessage("Email changed successfully!");
      setOpenPasswordModal(false);
      getAgenciesList();
      dispatch(resetChangeEmail());
    } else if (errorChangeEmail) {
      setOpenAlert(true);
    }
  }, [changedEmail, errorChangeEmail]);

  useEffect(() => {
    if (changedPassword?.ok) {
      setOpenAlert(true);
      setSuccessMessage("Password changed successfully!");
      setOpenPasswordModal(false);
      dispatch(resetChangePassword());
    } else if (errorchangePassword) {
      setOpenAlert(true);
    }
  }, [changedPassword, errorchangePassword]);

  const rows = useMemo(() => {
    if (!agenciesList.length) return [];

    return agenciesList
      .filter(
        ({ owner, name }) =>
          owner?.name?.search(regex) !== -1 ||
          owner?.email?.search(regex) !== -1 ||
          name?.search(regex) !== -1
      )
      .map((agency) => ({
        id: agency?.id,
        name: agency?.name,
        owner_with_picture: {
          name: agency?.owner?.name,
          profile_picture_url: agency?.owner?.profile_picture_url,
          agentId: agency?.owner?.id,
        },
        more_details: agency,
        email_address: agency?.owner?.email ? agency?.owner?.email : "-",
        clients: agency?.clients_count,
        cases: agency?.cases_count,
      }));
  }, [agenciesList, query]);

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 228,
        flex: 1,
      },
      {
        field: "owner_with_picture",
        headerName: "Owner",
        width: 188,
        flex: 1,
        renderCell: ({ value }) => (
          <AvatarWithName
            name={value.name}
            imageURL={value.profile_picture_url}
          />
        ),
        sortable: false,
      },
      {
        field: "email_address",
        headerName: "Email",
        width: 226,
        flex: 1,
      },
      {
        field: "clients",
        headerName: "Clients",
        flex: 1,
        minWidth: 140,
        sortable: false,
      },

      {
        field: "cases",
        headerName: "Cases",
        flex: 1,
        minWidth: 140,
        sortable: false,
      },
      {
        field: "getCredentials",
        headerName: "Actions",
        width: 150,
        renderCell: (params) => (
          <Box>
            <CustomMenuBox
              params={params}
              getPasswordModalHandler={getPasswordModalHandler}
            />
          </Box>
        ),
      },
    ],
    []
  );

  const lawFirmDetailHandler = (params) => {
    const currentRow = params.row;

    navigate(`/lawfirm/${params.id}`, {
      state: { rowData: currentRow },
    });
  };

  const submitText =
    menuItem === "getCredentials" ? "Done" : getChangeEmailPasswordText();

  return (
    <Stack>
      {error && (
        <AlertMessage
          message={error}
          severity="error"
          open={openAlert}
          handleClose={handleClose}
        />
      )}
      {errorCredential && (
        <AlertMessage
          message={errorCredential}
          severity="error"
          open={openAlert}
          handleClose={handleClose}
        />
      )}

      {errorChangeEmail && (
        <AlertMessage
          message={errorChangeEmail}
          severity="error"
          open={openAlert}
          handleClose={handleClose}
        />
      )}
      {errorchangePassword && (
        <AlertMessage
          message={errorchangePassword}
          severity="error"
          open={openAlert}
          handleClose={handleClose}
        />
      )}

      {openAlert && (
        <AlertMessage
          message={successMessage}
          severity="success"
          open={openAlert}
          handleClose={handleClose}
        />
      )}

      {openPasswordModal && (
        <>
          <AlertDialog
            data={modalContentData}
            submitText={submitText}
            isLoading={loading || loadingChangeEmail || loadingchangePassword}
            openModal={openPasswordModal}
            closeModal={closePasswordModalHandler}
            formSubmitFunction={
              menuItem === "getCredentials"
                ? closePasswordModalHandler
                : changeEmailPasswordHandler
            }
            maxWidth="727px"
            height={menuItem === "changeEmail" ? "757px" : "auto"}
            disabled={
              menuItem === "getCredentials"
                ? false
                : tabValue === "changeEmail"
                ? !newEmail.length || emailError
                : !password.length || password !== confirmedNewPassword
            }
          >
            <LogincredentialsUI
              lawFirmName={rowData?.name}
              pictureUrl={rowData?.owner_with_picture?.profile_picture_url}
              ownerName={rowData?.owner_with_picture?.name}
              emailAddress={rowData?.email_address}
              menuItem={menuItem}
              loginCredentials={loginCredentials}
              loadingCredential={loadingCredential}
              setCopied={setCopied}
              tabValue={tabValue}
              setTabValue={setTabValue}
              newEmail={newEmail}
              setNewEmail={setNewEmail}
              emailError={emailError}
              setEmailError={setEmailError}
              password={password}
              setPassword={setPassword}
              confirmedNewPassword={confirmedNewPassword}
              setConfirmedNewPassword={setConfirmedNewPassword}
            />
          </AlertDialog>
        </>
      )}

      {copied && (
        <Snackbar
          open={copied}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            severity="success"
            color="info"
            sx={{ zIndex: 1400, width: "200px", margin: "0 auto" }}
          >
            Text Copied!
          </Alert>
        </Snackbar>
      )}

      <Stack spacing={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <HeaderText textContent="Law Firms" fontSize="30px" />
        </Box>

        {loadingStats ? (
          <SkeletonGrid itemCount={3} height={175} md={4} />
        ) : (
          <Grid container spacing={4} width="100%">
            <Grid item md={4} pl="0 !important">
              <LawFirmCard
                headerText="Total Law firms"
                number={lawFirmStats?.agencies_count}
                imgName={buildingImg}
                alt="Total Law firms"
              />
            </Grid>
            <Grid item md={4}>
              <LawFirmCard
                headerText="Total Cases"
                number={lawFirmStats?.cases_count}
                imgName={folderImg}
                alt="Total Cases"
              />
            </Grid>
            <Grid item md={4} pr="0 !important">
              <LawFirmCard
                headerText="Active Subscriptions"
                number={lawFirmStats?.active_subscriptions}
                imgName={moneyImg}
                alt="Active Subscriptions"
              />
            </Grid>
          </Grid>
        )}

        {loading ? (
          <SkeletonGrid itemCount={1} height={600} md={12} />
        ) : (
          <CardUI
            maxWidth="100%"
            dashboard="true"
            responsive="true"
            // height="648px"
          >
            {/* {openModal && (
              <AlertDialog
                submitID={submitDeleteID}
                openModal={openModal}
                closeModal={modalCloseHandler}
                data={modalContentData}
              />
            )} */}

            {agenciesList && agenciesList?.length > 0 ? (
              <>
                <Stack
                  justifyContent="space-between"
                  alignItems="center"
                  direction="row"
                >
                  <Stack direction="row" gap="16px" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="16px"
                      width="100%"
                    >
                      <FormControl size="small" variant="outlined">
                        <InputLabel htmlFor="search-agents">Search</InputLabel>
                        <OutlinedInput
                          id="search-agents"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                          endAdornment={
                            <InputAdornment position="end">
                              <SearchOutlinedIcon />
                            </InputAdornment>
                          }
                          label="Search"
                          size="small"
                          sx={{ backgroundColor: "#FFF" }}
                        />
                      </FormControl>
                    </Box>
                  </Stack>
                </Stack>

                <div
                  style={{ height: 550, width: "100%" }}
                  className={classes.root}
                >
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    onRowClick={lawFirmDetailHandler}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                    }}
                    sx={{
                      cursor: " pointer !important",
                      "&": { border: "none" },
                    }}
                    rowsPerPageOptions={[5, 10, 100]}
                    disableRowSelectionOnClick
                    getRowId={(row) => row.id}
                  />
                </div>
              </>
            ) : (
              <Stack
                alignItems="center"
                justifyContent="center"
                height="calc(100vh - 460px)"
              >
                <Avatar
                  src={EmptyBox}
                  alt="coming soon"
                  sx={{
                    width: "128px",
                    height: "128px",
                    paddingBottom: "24px",
                  }}
                />
                <Typography variant="body1" color="secondary" pb={2}>
                  All law Firms will show up here
                </Typography>
              </Stack>
            )}
          </CardUI>
        )}
      </Stack>
    </Stack>
  );
}
