import React from "react";

const CopyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_4219_30658)">
        <path
          d="M9.33366 0.583984H2.33366C1.68908 0.583984 1.16699 1.10607 1.16699 1.75065V9.91732H2.33366V1.75065H9.33366V0.583984ZM11.0837 2.91732H4.66699C4.02241 2.91732 3.50033 3.4394 3.50033 4.08398V12.2507C3.50033 12.8952 4.02241 13.4173 4.66699 13.4173H11.0837C11.7282 13.4173 12.2503 12.8952 12.2503 12.2507V4.08398C12.2503 3.4394 11.7282 2.91732 11.0837 2.91732ZM11.0837 12.2507H4.66699V4.08398H11.0837V12.2507Z"
          fill="#D1D1D1"
        />
      </g>
      <defs>
        <clipPath id="clip0_4219_30658">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopyIcon;
