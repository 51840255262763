import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const LawFirmCard = ({
  headerText,
  number,
  imgName,
  alt,
  borderRadius,
  boxShadow = "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
}) => {
  return (
    <Box
      boxShadow={boxShadow}
      padding="32px"
      height="175px"
      borderRadius="8px"
      position="relative"
      overflow="hidden"
      width="100%"
      bgcolor="#fff"
    >
      <Stack alignItems="flex-start" justifyContent="space-between" gap={2}>
        <Typography variant="body1" fontSize="14px" fontWeight="500">
          {headerText}
        </Typography>
        <Typography variant="h3" fontSize="50px" fontWeight="700">
          {number}
        </Typography>
      </Stack>
      <Box
        position="absolute"
        bottom="-11px"
        right="-3px"
        sx={
          borderRadius && {
            width: "138px",
            height: "138px",
            borderRadius: "90px",
            background: "rgba(86, 104, 117, 0.06)",
            padding: "25px",
            bottom: "-27px",
            right: "-18px",
          }
        }
      >
        <img src={imgName} alt={alt} width="100%" height="100%" />
      </Box>
    </Box>
  );
};

export default LawFirmCard;
