import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

const AlertDialog = ({
  submitID,
  data,
  openModal,
  closeModal,
  formSubmitFunction,
  submitText = "Yes",
  maxWidth = "xs",
  height = "auto",
  children,
  isLoading = false,
  disabled,
}) => {
  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  return (
    <Dialog
      open={openModal}
      maxWidth={maxWidth}
      fullWidth={true}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          width: "100%",
          height: height,
          maxWidth: `${maxWidth} !important`,
        },
      }}
    >
      <DialogTitle
        sx={{ padding: "32px !important" }}
        id="alert-dialog-title"
        fontSize="20px"
      >
        {data.title}{" "}
        {data.lightText && (
          <span style={{ fontWeight: "400" }}>"{data.lightText}"</span>
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0 32px 20px !important",
        }}
      >
        {submitText === "Yes" ? (
          <DialogContentText id="alert-dialog-description">
            {data.subtitle}
          </DialogContentText>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions sx={{ padding: "16px 32px 32px" }}>
        <LoadingButton
          id={submitID}
          loading={isLoading}
          disabled={disabled}
          variant="contained"
          onClick={formSubmitFunction}
          color="primary"
        >
          {submitText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
